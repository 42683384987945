.Chart-Container {
	height: 275px;
}

.column-cga-group {
	width: 10%;
	padding-top: 8px;
	padding-bottom: 8px;
	padding-right: 0px;
	padding-left: 4px;
	border-bottom: 1px solid gray;
}

.column-cga-captain {
	width: 40%;
	padding: 8px;
	border-bottom: 1px solid gray;
}

.column-cga-attendance {
	width: 50%;
	padding: 8px;
	border-bottom: 1px solid gray;
}

.column-cga-1 {
	width: 10%;
	text-align: center;
	border-bottom: 1px solid gray;
	padding-top: 8px;
	padding-bottom: 8px;
}

.column-cga-2 {
	width: 30%;
	border-bottom: 1px solid gray;
	padding-top: 8px;
	padding-bottom: 8px;
}

.column-cga-3 {
	width: 7%;
	border-bottom: 1px solid gray;
	padding-top: 8px;
	padding-bottom: 8px;
}

.column-cga-4 {
	width: 25%;
	border-bottom: 1px solid gray;
	padding-top: 8px;
	padding-bottom: 8px;
}

.column-cga-5 {
	width: 18%;
	border-bottom: 1px solid gray;
	padding-top: 8px;
	padding-left: 4px;
	padding-bottom: 8px;
}

.column-cga-cn {
	width: 60%;
	padding-top: 6px;
	padding-bottom: 6px;
	padding-left: 16px;
	padding-right: 16px;
	border-bottom: 1px solid gray;
}

.column-cga-sts {
	width: 40%;
	padding-top: 6px;
	padding-bottom: 6px;
	padding-left: 16px;
	padding-right: 16px;
	border-bottom: 1px solid gray;
}

.column-cga-6 {
	width: 5%;
	padding-top: 6px;
	padding-bottom: 6px;
	padding-left: 0px;
	padding-right: 0px;
	text-align: center;
	border-bottom: 1px solid gray;
}

.column-cga-7 {
	width: 55%;
	padding: 6px;
	border-bottom: 1px solid gray;
}

.column-cga-8 {
	width: 40%;
	padding: 6px;
	border-bottom: 1px solid gray;
}

@media (min-width: 992px) {
	.Chart-Container {
		height: 350px;
	}

	.column-cga-group {
		width: 10%;
		padding: 16px;
		border-bottom: 1px solid gray;
	}
	
	.column-cga-captain {
		width: 40%;
		padding: 16px;
		border-bottom: 1px solid gray;
	}
	
	.column-cga-attendance {
		width: 50%;
		padding: 16px;
		border-bottom: 1px solid gray;
	}

	.column-cga-1 {
		width: 10%;
		text-align: center;
		padding: 16px;
		border-bottom: 1px solid gray;
	}
	
	.column-cga-2 {
		width: 30%;
		padding: 16px;
		border-bottom: 1px solid gray;
	}
	
	.column-cga-3 {
		width: 10%;
		padding: 16px;
		border-bottom: 1px solid gray;
	}
	
	.column-cga-4 {
		width: 40%;
		padding: 16px;
		border-bottom: 1px solid gray;
	}
	
	.column-cga-5 {
		width: 10%;
		padding: 16px;
		border-bottom: 1px solid gray;
	}

	.column-cga-cn {
		width: 40%;
		padding-top: 6px;
		padding-bottom: 6px;
		padding-left: 16px;
		padding-right: 16px;
		border-bottom: 1px solid gray;
	}
	
	.column-cga-sts {
		width: 60%;
		padding-top: 6px;
		padding-bottom: 6px;
		padding-left: 16px;
		padding-right: 16px;
		border-bottom: 1px solid gray;
	}
	
	.column-cga-6 {
		width: 5%;
		padding-top: 6px;
		padding-bottom: 6px;
		padding-left: 16px;
		padding-right: 16px;
		text-align: center;
		border-bottom: 1px solid gray;
	}
	
	.column-cga-7 {
		width: 35%;
		padding-top: 6px;
		padding-bottom: 6px;
		padding-left: 16px;
		padding-right: 16px;
		border-bottom: 1px solid gray;
	}
	
	.column-cga-8 {
		width: 60%;
		padding-top: 6px;
		padding-bottom: 6px;
		padding-left: 16px;
		padding-right: 16px;
		border-bottom: 1px solid gray;
	}
}