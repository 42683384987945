#MainWeatherImg > img {
	/* transform: scale(3); */
	height: 60px;
	width: 60px;
}

#WeatherImg > img {
	/* transform: scale(3); */
	height: 30px;
	width: 30px;
}

#Title {
	font-size: 16px;
	font-weight: 500;
	line-height: 1.5;
	text-transform: uppercase;
}

#SubTitle {
	font-size: 14px;
	/* font-weight: 700; */
	line-height: 1.43;
	margin-bottom: 16px;
	/* opacity: .6; */
}

#Degree {
	font-size: 42px;
	font-weight: 500;
	letter-spacing: -.7px;
	line-height: 1.07;
}

#DegreeMobile {
	font-size: 20px;
	font-weight: 500;
	letter-spacing: -.7px;
	line-height: 1.07;
}

#Celcius {
	font-size: 12px;
	line-height: 1.28;
	margin-left: -16px;
	opacity: .6;
}

#RealFeel {
	font-size: 11px;
	font-weight: 500;
	line-height: 1.43;
}

.detail {
	align-items: center;
	border-bottom: 1px solid rgba(0,0,0,.15);
	display: flex;
	flex-wrap: wrap;
	padding: 8px 0;
}

.spaced-content {
	align-items: center;
	display: -webkit-box;
	display: flex;
	justify-content: space-between;
}

.detail .label {
	font-size: 14px;
	line-height: 1.25;
	margin-right: 8px;
}

.detail .value {
	font-size: 14px;
	font-weight: 500;
	line-height: 1.33;
}

#Reservation-Box {
	background-color: rgb( 242, 255, 97 );
}

#Nop-Box {
	background-color: rgb( 242, 252, 126 );
}

#MemberVsGuest-Box {
	background-color: rgb(22,153,219);
}

#NewVsFrequent-Box {
	background-color: rgb(68,186,222);
}

#Buggy-Box {
	background-color: rgb( 252, 143, 73 );
}

#Caddy-Box {
	background-color: rgb( 252, 174, 124 );
}

#FnBService-Box {
	background-color: rgb( 40, 252, 58 );
}

#FnBProduction-Box {
	background-color: rgb( 77, 255, 92 );
}

#Driving-Box {
	background-color: rgb( 250, 80, 140 );
}

#RoomRate-Box {
	background-color: rgb( 255, 110, 161 );
}

.Weather-Font {
	font-size: 11px;
}

.WeatherTitle {
	font-size: 16px;
}

.small-box > .inner {
	font-size: 12px;
	height: 100px;
	font-weight: bold;
}

#Driving-Box > .inner {
	font-size: 9px;
	height: 100px;
	font-weight: bold;
}

#NewVsFrequent-Box > .inner {
	font-size: 10px;
	height: 100px;
	font-weight: bold;
}

.inner-box {
	font-size: 12px;
	font-weight: normal;
	height: 40px;
	text-align: left;
}

.inner-center-box {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	font-size: 12px;
	height: 30px;
	padding-top: 15px;
	font-weight: normal;
}

.inner-footer {
	height: 21px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
}

.CardBody-Chart {
	padding: 10px;
	padding-top: 5px;
}

.Chart-Container {
	height: 275px;
}

@media (min-width: 768px) {
	.Weather-Font {
		font-size: 0.875rem;
	}

	.WeatherTitle {
		font-size: 20px;
	}

	#MainWeatherImg > img {
		/* transform: scale(3); */
		height: 60px;
		width: 60px;
	}

	#WeatherImg > img {
		/* transform: scale(3); */
		height: 60px;
		width: 60px;
	}

	.inner-box {
		font-size: 1.25rem;
		font-weight: normal;
		height: 60px;
		text-align: left;
	}

	.inner-center-box {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		font-size: 1.25rem;
		height: 60px;
		padding-top: 15px;
		font-weight: normal;
	}

	.inner-footer {
		height: 21px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 20px;
	}

	.small-decimal {
		font-size: 16px; /* Adjust the size as needed */
		/* vertical-align: super; */
		margin-top: 4px;
	}

	.Content {
		font-size: 20px;
		font-weight: normal;
		text-align: right;
		height: 100%;
	}

	.Context {
		font-size: 16px;
		font-weight: normal;
		text-align: left;
		height: 100%;
		margin-top: 4px;
	}

	.CardBody-Chart {
		padding: 20px;
	}

	.Chart-Container {
		height: 350px;
	}
}